import React, {useEffect, useState} from 'react';
import DynamicReportsTable from "./DynamicReportsTable";
import DatePicker from "react-datepicker";
import {Button, Dimmer, Dropdown, Icon, Loader} from "semantic-ui-react";
import moment from "moment";
import {PracticeInfoList, PracticeList} from "../model/practice";
import { Provider } from "../model/provider";
import { Region } from "../model/region";
import * as practiceActions from "../redux/actions/practice";
import * as reportActions from "../redux/actions/report";
import * as providerActions from "../redux/actions/provider";
import * as referralActions from "../redux/actions/referrals"
import * as regionActions from "../redux/actions/regions";
import {useActions} from "../redux/actions";
import {RootState} from "../model";
import {connect} from "react-redux";
import {brands} from "../constants";
import _ from "lodash";
import ModalRS from './ModalRS';
import { isBlankNullOrUndefined } from '../utils/common';
import CountUpTimer from './CountUpTimer';

interface ReportProps {
    practiceList: Array<PracticeList>;
    practiceListAll: Array<PracticeList>;
    practiceInfoList: Array<PracticeInfoList>;
    user: any;
    providerList: Array<Provider>;
    regionsList: Array<Region>;
}

function ReportsX(props: ReportProps) {
    const practiceAction = useActions(practiceActions);
    const reportAction = useActions(reportActions);
    const providerAction = useActions(providerActions);
    const regionAction = useActions(regionActions);
    const referralAction = useActions(referralActions);

    const ageList: Array<{}> = [
        {
            key: 0,
            value: 'below,7',
            text: 'Below 7'
        },
        {
            key: 1,
            value: 'between,7,18',
            text: 'Between 7 to 18'
        },
        {
            key: 2,
            value: 'above,8',
            text: 'Above 8'
        },
        {
            key: 3,
            value: 'above,14',
            text: 'Above 14'
        },
        {
            key: 4,
            value: 'above,18',
            text: 'Above 18'
        }
    ]


    const initialState: any = {
        TableData: [[{title: 'No Records Found'}]],
        column: -1,
        direction: 'sort',
        reportData: [],
        refData: [],
        onChangeAge: [],
        onChangePracticeOrProvider: true,
        brandFilter: brands,
        regionsFilter: props.regionsList,
        practiceFilter: props.practiceListAll
    };
    const initialFilter: any = {
        date: new Date(moment().startOf('month').toString()),
        practice: 'all',
        provider: 'all',
        brand: 'all',
        region: 'all',
        age: [],
        practiceOrProvider: true,
        practiceInfo: {},
        modalPracticeOpen:false,
        desc: ''
    };
    const [filter, SetFilter] = useState(initialFilter);
    const [state, setStates] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [tableHead, SetTableHead] = useState([
        {title: `Practice` },
        {title: 'PT Count'},
        {title: 'FV PT Count'},
        {title: 'OS Referrals'},
        {title: 'Ortho Referrals'},
        {title: '% OS PT RO'},
        {title: '% OS FV PT RC'},
        {title: '% Ortho PT RO'},
        {title: '% Ortho FV PT RO'},
    ])

    useEffect(() => {
        providerAction.fetchProvider({userId: props.user.id});
        regionAction.fetchRegionsAll().then((regions: any) => {
            const regs: any = regions;
            practiceAction.fetchAllPractices({type: 'all'}).then((practices: any) => {
                loadReport(state.onChangePracticeOrProvider, practices, regs);
            });
        })
    }, []);

    const loadReport = (practiceOrProvider: boolean, practices: any, regions: any) => {
        setLoading(true);
        const params = {
            date: moment(filter.date).format('YYYY-MM-DD'),
            practice: filter.practice,
            region: filter.region,
            brand: filter.brand,
            provider: filter.provider,
            age: state.onChangeAge
        }
        const refParams = {
            startDate: filter.date,
            endDate: moment(filter.date, "YYYY-MM-DD").endOf('month').format('Y-MM-DD'),
            filterPractice: filter.practice,
            age: state.onChangeAge
        }
        reportAction.fetchReferOutStats(params).then((res: any) => {
            const referOutStats:any = res;
            referralAction.allReferral(refParams).then((refs: any) => {
                populateData(referOutStats, practices, regions, practiceOrProvider, refs);
                setLoading(false);
            });
        });

    }

    const getCalculationsForPractice = (
        practice: any,
        referOutStats: any,
        fvCount: any,
        patients: any,
        refs: any
    ) => {
        let oralReferrals = 0;
        let orthoReferrals = 0;
        let oralCalcValue = 0;
        let orthoCalcValue = 0;
        let percentFVOral = 0;
        let percentPTOral = 0;
        let percentFVOrtho = 0;
        let percentPTOrtho = 0;

        const practiceName = practice.charAt(0).toUpperCase() + practice.slice(1);

        if(refs.production[practiceName] !== undefined && referOutStats[practice] !== undefined)
        {
            refs.production[practiceName].data.forEach((stats: any) => {
                if(stats.type.includes('oral')) oralReferrals++
                if(stats.type.includes('ortho')) orthoReferrals++
                if(stats.ProcDate === stats.FirstVisit && stats.PatNum === stats.PatNum) {
                    if(stats.type.includes('oral')) oralCalcValue++;
                    if(stats.type.includes('ortho')) orthoCalcValue++;
                }
            })
        }

        if(oralReferrals && fvCount) {
            percentFVOral = Number(((oralCalcValue / fvCount) * 100).toFixed(1));
        }
        if(oralReferrals && patients) {
            percentPTOral = Number(((oralReferrals / patients) * 100).toFixed(1));
        }

        if(orthoReferrals && fvCount) {
            percentFVOrtho = Number((( orthoCalcValue / fvCount)* 100).toFixed(1));
        }
        if(orthoReferrals && patients) {
            percentPTOrtho = Number(((orthoReferrals / patients)* 100).toFixed(1));
        }
        return {
            data: [
                { title: practice, type: 'practice' },
                { title: patients },
                { title: fvCount },
                { title: oralReferrals },
                { title: orthoReferrals },
                { title: percentPTOral ? `<div class="btn percentValues" data-numerator="${oralReferrals}" data-denominator="${patients}" data-type="Oral-Patients">${percentPTOral}%</div>` : '0%'},
                { title: percentFVOral ? `<div class="btn percentValues" data-numerator="${oralCalcValue}" data-denominator="${fvCount}" data-type="Oral-FirstVisit">${percentFVOral}%</div>` : '0%'},
                { title: percentPTOrtho ? `<div class="btn percentValues" data-numerator="${orthoReferrals}" data-denominator="${patients}" data-type="Ortho-Patients">${percentPTOrtho}%</div>` : '0%'},
                { title: percentFVOrtho ? `<div class="btn percentValues" data-numerator="${orthoCalcValue}" data-denominator="${fvCount}" data-type="Ortho-FirstVisit">${percentFVOrtho}%</div>` : '0%'}
            ],
            totals: {
                ptCount: patients,
                fvCount: fvCount,
                oralRefs: oralReferrals,
                orthoRefs: orthoReferrals,
                percentFVOrtho: orthoCalcValue,
                percentOSFVPTCount: oralCalcValue
            }
        }
    }

    const getCalculationsForProvider = (
        providerList: any,
        practice: any,
        doc: any,
        refs: any
    ) => {
        let fvCount: any = 0;
        let percentFVOral = 0;
        let percentPTOral = 0;
        let percentFVOrtho = 0;
        let percentPTOrtho = 0;
        let oralReferrals = 0;
        let orthoReferrals = 0;
        let oralCalcValue = 0;
        let orthoCalcValue = 0;
        let patients: any = providerList[doc].length;

        if(providerList[doc].length) {
            providerList[doc].forEach((item: any) => {
                if(item.ProcDate === item.FirstVisit) {
                    fvCount++;
                    if(!isBlankNullOrUndefined(item.Type) && item.Type.includes('oral')) oralReferrals++;
                if(!isBlankNullOrUndefined(item.Type) &&  item.Type.includes('ortho')) orthoReferrals++;
                }
            })
        }
        if(refs.production[practice] !== undefined && refs.production[practice].data.length) {
            refs.production[practice].data.forEach((stats: any) => {
                if(stats.Dr_Name === `Dr. ${doc}`) {
                    if(stats.type.includes('oral')) oralReferrals++;
                    if(stats.type.includes('ortho')) orthoReferrals++;
                }
            });
        }

        if(oralReferrals !== 0 && fvCount !== 0) {
            percentFVOral = Number(((oralCalcValue / fvCount) * 100).toFixed(1));
        }
        if(oralReferrals !== 0 && patients.length !== 0) {
            percentPTOral = Number(((oralReferrals / patients) * 100).toFixed(1));
        }
        if(orthoReferrals !== 0 && fvCount !== 0) {
            percentFVOrtho = Number(((orthoCalcValue / fvCount) * 100).toFixed(1));
        }
        if(orthoReferrals !== 0 && patients.length !== 0) {
            percentPTOrtho = Number(((orthoReferrals / patients) * 100).toFixed(1));
        }
        return {
            data: [
                { title: doc, type: 'doctor', providerList:  providerList[doc]},
                { title: patients, desc: 'patientCount' },
                { title: fvCount, desc: 'fvCount' },
                { title: oralReferrals, desc: 'oralReferrals' },
                { title: orthoReferrals, desc: 'orthoReferrals' },
                { title: percentPTOral ? `<div class="btn percentValues" data-numerator="${oralReferrals}" data-denominator="${patients}" data-type="Oral-Patients">${percentPTOral}%</div>` : '0%'},
                { title: percentFVOral ? `<div class="btn percentValues" data-numerator="${oralCalcValue}" data-denominator="${fvCount}" data-type="Oral-FirstVisit">${percentFVOral}%</div>` : '0%'},
                { title: percentPTOrtho ? `<div class="btn percentValues" data-numerator="${orthoReferrals}" data-denominator="${patients}" data-type="Ortho-Patients">${percentPTOrtho}%</div>` : '0%'},
                { title: percentFVOrtho ? `<div class="btn percentValues" data-numerator="${orthoCalcValue}" data-denominator="${fvCount}" data-type="Ortho-FirstVisit">${percentFVOrtho}%</div>` : '0%'}
            ],
            totals : {
                ptCount: patients,
                fvCount: fvCount,
                oralRefs: oralReferrals,
                orthoRefs: orthoReferrals,
                oralCalcValue,
                orthoCalcValue,
                percentFVOrtho: orthoCalcValue,
                percentOSFVPTCount: oralCalcValue
            }
        }
    }

    const populateData = (referOutStats: any, practices: any, regions: any, practiceOrProvider: boolean, refs: any) => {
        const myTableData: any = [];
        const tableFooter: any = [];
        let totalPTCount = 0;
        let totalFVPTCount = 0;
        let totalOSCount = 0;
        let totalOTCount = 0;
        let totalOFVPTCount = 0;
        let totalOSFVPTCount = 0;
        let totalOralCalcValue = 0;
        let totalOrthoCalcValue = 0;

        if (referOutStats && practices) {
            practices.forEach((prac: any) => {
                const practice:string = prac.practice.toLowerCase();

                let fvCount:number = 0;
                let providerList: any = [];
                let doctors: any = [];

                if(practiceOrProvider && referOutStats[practice] !== undefined) {
                    const patients:number = referOutStats[practice].ptCount.total;
                    referOutStats[practice].ptCount.data.forEach((stats: any) => {
                        if(stats.ProcDate === stats.FirstVisit) {
                            fvCount++;
                        }
                    })
                    if (refs.production[practice] !== undefined && referOutStats[practice].ptCount.data.length) {
                        refs.production[practice].data.forEach((stats: any) => {
                            if(stats.ProcDate === stats.FirstVisit) {
                                if(stats.type.includes('oral')) totalOralCalcValue++;
                                if(stats.type.includes('ortho')) totalOrthoCalcValue++;
                            }
                        })
                    }

                    totalPTCount += getCalculationsForPractice(practice, referOutStats, fvCount, patients, refs).totals.ptCount;
                    totalFVPTCount += getCalculationsForPractice(practice, referOutStats, fvCount, patients, refs).totals.fvCount;
                    totalOSCount += getCalculationsForPractice(practice, referOutStats, fvCount, patients, refs).totals.oralRefs;
                    totalOTCount += getCalculationsForPractice(practice, referOutStats, fvCount, patients, refs).totals.orthoRefs;
                    totalOFVPTCount += getCalculationsForPractice(practice, referOutStats, fvCount, patients, refs).totals.percentFVOrtho;
                    totalOSFVPTCount += getCalculationsForPractice(practice, referOutStats, fvCount, patients, refs).totals.percentOSFVPTCount;
                    myTableData.push(getCalculationsForPractice(practice, referOutStats, fvCount, patients, refs).data);

                }
                else if(!practiceOrProvider && referOutStats[practice] !== undefined){
                    referOutStats[practice].ptCount.data.forEach((referral: any) => {
                        const provider:any = referral.Dr_Name;
                        if( providerList[provider] === undefined) {
                            providerList[provider] = [];
                        }
                        if(!doctors.includes(provider)) {
                            doctors.push(provider)
                        }
                        if(referral.Dr_Name === provider) {
                            providerList[provider].push(referral);
                        }
                    })
                    doctors.forEach((doc:any) => {
                        totalPTCount += getCalculationsForProvider(providerList, practice, doc, refs).totals.ptCount;
                        totalFVPTCount += getCalculationsForProvider(providerList, practice, doc, refs).totals.fvCount;
                        totalOSCount += getCalculationsForProvider(providerList, practice, doc, refs).totals.oralRefs;
                        totalOTCount += getCalculationsForProvider(providerList, practice, doc, refs).totals.orthoRefs;
                        totalOralCalcValue += getCalculationsForProvider(providerList, practice, doc, refs).totals.oralCalcValue;
                        totalOrthoCalcValue += getCalculationsForProvider(providerList, practice, doc, refs).totals.orthoCalcValue;
                        totalOFVPTCount += getCalculationsForProvider(providerList, practice, doc, refs).totals.percentFVOrtho;
                        totalOSFVPTCount += getCalculationsForProvider(providerList, practice, doc, refs).totals.percentOSFVPTCount;
                        myTableData.push(getCalculationsForProvider(providerList, practice, doc, refs).data);
                    });
                    reportAction.saveProviderList(providerList);
                }
            })

            let totalOralPTPercent = 0;
            let totalOralFVPercent = 0;
            let totalOrthoPTPercent = 0;
            let totalOrthoFVPercent = 0;

            if(totalPTCount !== 0 && totalOSCount !== 0) {
                totalOralPTPercent = Number(((totalOSCount / totalPTCount) * 100).toFixed(1))
            }
            if(totalPTCount !== 0 && totalOralCalcValue) {
                totalOralFVPercent = Number((( totalOralCalcValue / totalFVPTCount) * 100).toFixed(1));
            }
            if(totalFVPTCount !== 0 && totalOTCount !== 0) {
                totalOrthoPTPercent = Number(((totalOTCount / totalPTCount) * 100).toFixed(1))
            }
            if(totalFVPTCount !== 0 && totalOrthoCalcValue) {
                totalOrthoFVPercent = Number((( totalOrthoCalcValue / totalFVPTCount) * 100).toFixed(1))
            }

            myTableData.push([
                {title: "Totals"},
                {title: totalPTCount},
                {title: totalFVPTCount},
                {title: totalOSCount},
                {title: totalOTCount},
                {title: totalOralPTPercent !== 0 ? `<div class="btn percentValues" data-numerator="${totalOSCount}" data-denominator="${totalPTCount}" data-type="Oral-Patients">${totalOralPTPercent}%</div>` : '0%'},
                {title:  totalOSFVPTCount !== 0 ? totalOSFVPTCount + '%' : totalOralFVPercent ? totalOralFVPercent : '0%'},
                {title: totalOrthoPTPercent !== 0 ? `<div class="btn percentValues" data-numerator="${totalOTCount}" data-denominator="${totalPTCount}" data-type="Ortho-Patients">${totalOrthoPTPercent}%</div>` : '0%'},
                {title: totalOFVPTCount !== 0 ? totalOFVPTCount + '%' : totalOrthoFVPercent ? totalOrthoFVPercent : '0%'},
            ])
        }
        setStates({...state, TableData: myTableData, TableFooter: tableFooter, onChangePracticeOrProvider: practiceOrProvider, practiceFilter: practices, regionsFilter: regions});
    }

    const onChangeFilter = (name: string, value: any) => {
        SetFilter({...filter, [name]: value});

        if (name === 'brand') {
            if (value !== 'all') {
                let regionsList: any =[];
                let practices: any = [];
                if(filter.region !== 'all') {
                    const region:any = props.regionsList.find((region:any) => region.name === filter.region)
                    const practicesInfoList = props.practiceInfoList.filter((pracInfoObj: any) => pracInfoObj.regionId === region.id);
                    practicesInfoList.filter((pracInfoObj: any) => {
                        props.practiceListAll.filter((pracObj:any) => {
                            if(pracInfoObj.practiceId === pracObj.id && pracObj.practiceabbr.includes(value) && practices.includes(pracObj)) {
                                practices.push(pracObj);
                            }
                        })
                    })
                }
                else {
                    props.practiceListAll.filter((pracObj:any) => pracObj.practiceabbr.includes(value) && practices.push(pracObj))
                    props.practiceInfoList.filter((pracInfoObj: any) => {
                        practices.filter((pracObj: any) => {
                            pracInfoObj.practiceId === pracObj.id && props.regionsList.find((region: any) => (pracInfoObj.regionId === region.id && !regionsList.includes(region)) && regionsList.push(region));
                        })
                    });
                    setStates({...state, practiceFilter: practices, regionsFilter: regionsList});
                }
            }
            else {
                if(filter.region !== 'all') {
                    let practices: any = [];
                    const region:any = props.regionsList.find((region:any) => region.name === filter.region)
                    const practicesInfoList = props.practiceInfoList.filter((pracInfoObj: any) => pracInfoObj.regionId === region.id);
                    practicesInfoList.filter((pracInfoObj: any) => {
                        props.practiceListAll.filter((pracObj:any) => {
                            if(pracInfoObj.practiceId === pracObj.id && practices.includes(pracObj)) {
                                practices.push(pracObj);
                            }
                        })
                    })
                    setStates({...state, practiceFilter: practices, regionsFilter: props.regionsList});
                }

                    setStates({...state, brandFilter: brands, regionsFilter: props.regionsList, practiceFilter: props.practiceListAll});
               
            }
        }
        if (name === 'region') {
            let practices: any = [];
            let brandsList: any = [];
            if (value !== 'all') {
                if(filter.brand !== 'all') {
                    let pracContainer:any = [];
                    practices = props.practiceListAll.filter((pracObj:any) => pracObj.practiceabbr.includes(filter.brand));
                    const region:any = props.regionsList.find((region:any) => region.name === value)
                    const practicesInfoList = props.practiceInfoList.filter((pracInfoObj: any) => pracInfoObj.regionId === region.id);
                    practices.filter((prac: any) => {
                        practicesInfoList.filter((pracInfoObj:any) => {
                            if (prac.practice === pracInfoObj.practiceName) {
                                !pracContainer.includes(prac) && pracContainer.push(prac)
                            }
                        });
                    });
                    pracContainer.filter((pracObj: any) => {
                        brands.filter((brand: any) => {
                            if(pracObj.practiceabbr.includes(brand.text) && !brandsList.includes(brand)) brandsList.push(brand);
                        })
                    })
                    setStates({...state, practiceFilter: pracContainer, brandsFilter: brandsList});
                }
                else {
                    let pracContainer:any = [];
                    const region:any = props.regionsList.find((region:any) => region.name === value)

                    const practicesInfoList = props.practiceInfoList.filter((pracInfoObj: any) => pracInfoObj.regionId === region.id);

                    props.practiceListAll.filter((prac: any) => {
                        practicesInfoList.filter((pracInfoObj:any) => {
                            if (prac.practice === pracInfoObj.practiceName) {
                                !pracContainer.includes(prac) && pracContainer.push(prac)
                            }
                        });
                    });
                    pracContainer.filter((pracObj: any) => {
                        brands.filter((brand: any) => {
                            if(pracObj.practiceabbr.includes(brand.text) && !brandsList.includes(brand)) brandsList.push(brand);
                        })
                    })
                    setStates({...state, practiceFilter: pracContainer, brandFilter: brandsList });
                }
            }
            else {
                if(filter.brand !== 'all') {
                    practices = props.practiceListAll.filter((pracObj:any) => pracObj.practiceabbr.includes(filter.brand));
                    setStates({...state, practiceFilter: practices, brandFilter: brands});
                }
                if(filter.brand === 'all' && filter.practice === 'all') {
                    setStates({...state, brandFilter: brands, regionsFilter: props.regionsList, practiceFilter: props.practiceListAll});
                }
            }
        }

        if (name === 'practice') {
            let regionsList: any = [];
            let brandsList: any = [];
            let practices: any = [];

            if (value !== 'all') {
                const practice: any = props.practiceListAll.find((pracObj: any) => pracObj.practiceabbr === value);
                const practicesInfoList: any = props.practiceInfoList ? props.practiceInfoList.find((pracInfoObj: any) => pracInfoObj.practiceName === practice.practice) : [];
                brands.filter((brand: any) => {
                    if(practicesInfoList.brand.includes(brand.text) && !brandsList.includes(brand)) brandsList.push(brand);
                })
                props.regionsList.find((region: any) => practicesInfoList.regionId === region.id && regionsList.push(region));
                setStates({...state, brandFilter: brandsList, regionsFilter: regionsList});
            }
            else {
                if(filter.brand !== 'all') {
                    practices = props.practiceListAll.filter((pracObj:any) => pracObj.practiceabbr.includes(filter.brand));
                    practices.filter((pracObj: any) => {
                        props.practiceInfoList.filter((pracInfoObj: any) => {
                            if(pracInfoObj.practiceName === pracObj.practice) {
                                props.regionsList.filter((region: any) => {
                                    if(region.id === pracInfoObj.regionId && !regionsList.includes(region)) {
                                        regionsList.push(region);
                                    }
                                });
                            }
                        })
                    });
                    setStates({...state, regionsFilter: regionsList, brandFilter: brands});
                }
                if(filter.brand === 'all' && filter.region === 'all') {
                    setStates({...state, brandFilter: brands, regionsFilter: props.regionsList, practiceFilter: props.practiceListAll});
                }
            }
        }
    }

    const handleSearch = (name: string, value: any) => {
        if (value === 'practice') {
            SetTableHead([
                {title: 'Practice'},
                {title: 'PT Count'},
                {title: 'FV PT Count'},
                {title: 'OS Referrals'},
                {title: 'Ortho Referrals'},
                {title: '% OS PT RO'},
                {title: '% OS FV PT RC'},
                {title: '% Ortho PT RO'},
                {title: '% Ortho FV PT RO'},
            ]);
            loadReport(true, state.practiceFilter, state.regionsFilter);
        }
        else if(value === 'provider') {
            SetTableHead([
                {title: 'Provider'},
                {title: 'PT Count'},
                {title: 'FV PT Count'},
                {title: 'OS Referrals'},
                {title: 'Ortho Referrals'},
                {title: '% OS PT RO'},
                {title: '% OS FV PT RC'},
                {title: '% Ortho PT RO'},
                {title: '% Ortho FV PT RO'},
            ])
            loadReport(false, state.practiceFilter, state.regionsFilter);
        }
    }

    const handleSort=(clickedColumn:number)=>()=>{
        let {column,direction, TableData}=state
        if(column !== clickedColumn){
            column = clickedColumn;
            TableData = _.sortBy(TableData,'data', function (data){
                return data[clickedColumn].title;
            });
            direction = 'sort ascending';
            setStates({...state, TableData: TableData, column, direction});
            return
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setStates({...state, TableData: TableData.slice(), direction});
    }
    const modalRSOpen = (practice: any, desc: string) => {
        const practiceInfo = practice;
        const practiceOpen = true;
        setStates({...state, practiceInfo, practiceOpen, desc});
      }

      const closeRSModal = () => {
        const practiceInfo = {};
        const practiceOpen = false;
        const desc = ''
        setStates({...state, practiceInfo, practiceOpen, desc});
    }

    const sendReferStatsDetailsEmail = (typeData:[], name: string, detailsModalType: string, title: string) => {
		const filterData = {
			typeData : typeData,
            name: name,
            detailsModalType: detailsModalType,
            title: title
		}
		reportAction.sendReferStatsReportDetails(filterData);
	};

    return (
        <div className="calendar-container">
            {
                loading &&
                <CountUpTimer />
            }
            <div className="ui card">
            {state.practiceOpen && <ModalRS practiceInfo={state.practiceInfo} sendReferStatsDetailsEmail={sendReferStatsDetailsEmail}  handleClose={closeRSModal} desc={state.desc} detailsModalType="byPractice"/>}
                <div className="content pb0">
                    <h2 className="float-left mr10">
                        All Refer Out Stats
                    </h2>
                    <div className="practice-provider-container float-right mr10 mb15">
                        <button id="practice" className="btn-reports-practice pr-10 pl-5 cursorPointer" style={state.onChangePracticeOrProvider ? {background:"#FFA905", color: "white"}: {color: "#4e555b"}} onClick={(e) => setStates({...state, onChangePracticeOrProvider: true})}>Practice</button>
                        <span className="report-btn-separator">OR</span>
                        <button id="provider" className="btn-reports-provider cursorPointer" style={!state.onChangePracticeOrProvider ? {background:"#FFA905", color: "white"}: {color: "#4e555b"}} onClick={(e) => setStates({...state, onChangePracticeOrProvider: false})}>Provider</button>
                    </div>
                    <p/>
                </div>
                <div className="ui card">
                    <div className="content pb0">
                        <div className="container refer-out-stats-filters">
                            <div className="month-year-picker mr10">
                                <DatePicker
                                    name="date"
                                    className='ui reports-custom-date-input cursorPointer'
                                    dateFormat="MMM yyyy"
                                    showMonthYearPicker={true}
                                    selected={filter.date}
                                    onChange={(date: any) => onChangeFilter('date', date)}
                                />
                                <Icon className="calendar-icon cursorPointer" name="calendar alternate outline" />
                            </div>

                            <div>
                                <Dropdown
                                    search
                                    className="mr10 mb15"
                                    name="brand"
                                    placeholder="Brand"
                                    selection
                                    options={
                                        state.brandFilter ? [
                                            {
                                                text: 'All Brands',
                                                value: 'all',
                                                key: 'All'
                                            },
                                            ...state.brandFilter.map((brand: any) => {
                                                return {
                                                    key: brand.key,
                                                    value: brand.value,
                                                    text: brand.text,
                                                };
                                            })
                                        ]: []
                                    }
                                    onChange={(_, brand) => onChangeFilter('brand', brand.value)}
                                />
                                <Dropdown
                                    search
                                    className="mr10 mb15"
                                    name="region"
                                    placeholder="Region"
                                    selection
                                    options={
                                        state.regionsFilter ? [
                                            {
                                                key: "ALL",
                                                value: "all",
                                                text: "All Regions",
                                            },
                                            ...state.regionsFilter.map((region: any) => {
                                                return {
                                                    key: region.id,
                                                    value: region.name,
                                                    text: region.name,
                                                };
                                            })
                                        ]: []
                                    }
                                    onChange={(_, region) => onChangeFilter('region', region.value)}
                                />
                                <Dropdown
                                    search
                                    className="mr10 mb15"
                                    name="practice"
                                    placeholder="Practice"
                                    selection
                                    options={
                                        state.practiceFilter
                                            ? [
                                                {
                                                    key: "all",
                                                    value: "all",
                                                    text: "All Practices",
                                                },
                                                ...state.practiceFilter.map((practice: any) => {
                                                    return {
                                                        key: practice.id,
                                                        value: practice.practiceabbr,
                                                        text: practice.practice,
                                                    };
                                                }),
                                            ]
                                            : []
                                    }
                                    onChange={(_, practice) => onChangeFilter('practice', practice.value)}
                                />

                                <Dropdown
                                    search
                                    className="mr10 mb15"
                                    name="provider"
                                    placeholder="Provider"
                                    selection
                                    options={
                                        props.providerList ? [
                                            {
                                                key: "all",
                                                value: "all",
                                                text: "All Providers",
                                            },
                                            ...props.providerList.map((provider: any) => {
                                                return {
                                                    key: provider.id,
                                                    value: provider.abbr,
                                                    text: `${provider.firstName} ${provider.lastName}`,
                                                };
                                            }),
                                        ] : []
                                    }
                                    onChange={(_,provider) => onChangeFilter('provider', provider.value)}
                                />

                                <Dropdown
                                    search
                                    className="mr10 mb15"
                                    name="age"
                                    placeholder="Age ( Default: All )"
                                    multiple
                                    selection
                                    options={
                                        ageList ? [
                                        ...ageList.map((age: any) => {
                                                return {
                                                    key: age.key,
                                                    value: age.value,
                                                    text: age.text,
                                                };
                                            }),
                                        ] : []
                                    }
                                    onChange={(_,age) => setStates({...state, onChangeAge: age.value})}
                                />
                                <Button className='float-right' secondary={true} onClick={() => handleSearch('practiceOrProvider', state.onChangePracticeOrProvider ? 'practice' : 'provider')}>
                                    <Icon name='search'/> Filter
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <DynamicReportsTable
                        id={'referout-stats-table'}
                        key={0}
                        tableHead={tableHead}
                        className={'table celled single line table unstackable tableStyle table-striped table-hover statsSetup cursorPointer'}
                        heading={""}
                        onHeadClick={handleSort}
                        tableData={state.TableData.length !== 0 ? state.TableData : [[{title: 'No Records Found'}]]}
                        sortedColumn={state.column}
                        direction={state.direction}
                        source="referOutStats"
                        modalRSOpen={modalRSOpen}
                    />
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state: RootState) {
    return {
        practiceList: state.practice.practiceList,
        practiceListAll: state.practice.practiceListAll,
        user: state.auth.user,
        providerList: state.provider.providerList,
        regionsList: state.region.regionsList,
        practiceInfoList: state.practice.practiceInfoList
    };
}
export default connect(mapStateToProps)(ReportsX);
